body {
  font-family: "Titillium Web", sans-serif;
}

@font-face {
  font-family: Rubik;
  src: url(../public/assets/fonts/Rubik-Regular.ttf);
}

::placeholder { /* Most modern browsers support this now. */
  color:    grey !important;
}
.form-select{
  color:grey;
}
.form-control{
  color:grey !important;
}


.element.style {
  background: red;
}
