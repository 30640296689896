.ndauConnect .modal-content {
  background-color: #b6a6e6;
}

.ndauConnect .modal-title {
  color: #fcfcfc;
}

.scan-qrcode-text {
  color: #f1f1f1;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.qrcode-box {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
